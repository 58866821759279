import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { result } from 'lodash';
import env from "../env";
import Spinner from 'react-bootstrap/Spinner';
// import requestPromise from "request-promise"
// import { reduce } from 'lodash';
// const request = require('request');

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			error: "",
			loading: false

		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	validateForm() {
		const { username } = this.state;
		const { password } = this.state;
		if (username.length > 0 && password.length > 0) {
			return true;
		}
		else {
			//console.log("Enter valid username id");
			return false;
		}
	}

	async gotoReport(tokenKey,tokenKeyUS) {
		if (!this.state.error) {
			this.props.setroute('Select', tokenKey, tokenKeyUS, this.state.username, this.state.password);
		}
	}

	handleSubmit = async () => {
		try {
			this.setState({ loading: true })
			let tokenKey = await this.getTokens();
			let tokenKeyUS = await this.getTokens(true); // true for us
			if (tokenKey && tokenKeyUS) {
				this.setState({ error: "" })
				this.gotoReport(tokenKey,tokenKeyUS);
			} else {
				this.setState({ error: "Incorrect Username or Password , Please verify it." });
				this.setState({ loading: false })
			}

		} catch (error) {
			console.log('handleSubmit error', error)
		}
	}

	async getTokens(us=false) {
		try {
			let Akey = null;
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Access-Control-Allow-Origin", "http://localhost.afreespace.com:8443");

			var raw = JSON.stringify({
				"username": this.state.username,
				"password": this.state.password
			});

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			let req = await fetch(us ? env.loginus : env.login, requestOptions).catch(error => console.log('*********error', error));
			Akey = req.headers.get('x-auth-key');
			return Akey;

		} catch (error) {
			console.log({ error });
			return null;
		}
	}


	componentDidMount = async () => { // create the interval once component is mounted
		console.log("we are in the login page");

	}

	render() {
		return (
			<div className="Login" style={{ marginBottom: 20 }}>
				{this.state.error ? <div style={{ color: "red", textAlign: "center", fontSize: "20px" }}> {this.state.error} </div> : ""}
				<Form>
					<Form.Group size="lg" controlId="username">
						<Form.Label>Username:</Form.Label>
						<Form.Control
							autoFocus
							type="username"
							value={this.state.username}
							placeholder="Username"
							onChange={(e) => this.setState({ username: e.target.value })}
						/>
					</Form.Group>
					<Form.Group size="lg" controlId="password">
						<Form.Label>Password:</Form.Label>
						<Form.Control
							type="password"
							value={this.state.password}
							placeholder="Password"
							onChange={(e) => this.setState({ password: e.target.value })}
						/>
					</Form.Group>
					<Button block size="lg" type="button" onClick={() => this.handleSubmit()} disabled={!this.validateForm()} style={{borderRadius:"10px", background:"linear-gradient(90deg, #F7707D 0%, #F29173 100%)" ,color:"#ffffff",borderColor:"transparent"}}>
						{this.state.loading === false ? 'Login' : <Spinner animation="border" size="sm" style={{color: 'white'}}/>}
					</Button>
				</Form>
			</div>
		)
	}
}
export default Login;