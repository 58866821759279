import React , { useState } from 'react'
import Select , { components } from 'react-select'
import floorIdIcon from "../image/floorIdLogo.svg"
import dropdown from "../image/dropdown.svg"

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={dropdown}
            style={{ marginRight:"20px" }}
			alt="Dropdown"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const styles = {
    valueContainer: base => ({
      ...base,
      paddingLeft: 48
    } ),
      control: (base, { hasValue, isFocused }) => ({
        ...base,
        background: "#fff",
        border: hasValue
          ? isFocused ? "solid 1px #43dc94  !important" : "1px solid rgba(0, 0, 0,0.12)  !important"
          : isFocused ? "solid 1px #43dc94  !important" : "solid 1px rgba(0, 0, 0,0.12)",
        backgroundColor: hasValue
          ? "background-color: rgb(255, 255, 255)"
          : "#f7f7f7",
        borderColor: hasValue
          ? isFocused ? "#43dc94  !important" : "rgba(0, 0, 0,0.12)"
          : isFocused ? "#43dc94  !important" : "rgba(0, 0, 0,0.12) !important",

        boxShadow: isFocused ? "0 0 0 1px #43dc94  !important" : "",
        borderRadius: "10px",
        "&:hover": {
          // border: "1px solid rgb(67, 220, 148)",
          // boxShadow: "0 0 0 1px #43dc94",
          cursor:"pointer" 
        }
        
      }),
      menu: (base) => ({
        ...base,
        marginTop: 0,
        background:"#fffff",
        borderRadius:"10px",
        marginTop:"1px"
        // position:"relative",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,  
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#CAC8C8",
          borderRadius:"50px"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#CAC8C8;"
        }   
      }),

      option: (styles, { isSelected }) => {     
        return {
          ...styles,
          backgroundColor: isSelected ? "rgba(67, 220, 148, 0.12)" : null,
          borderRadius:"10px",
          color: "#444444",
          ":hover": {
            backgroundColor: "rgba(67, 220, 148, 0.12)",
          },
        };
      }
    };

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img
              src={floorIdIcon}
              style={{ position: 'absolute', left: 18 }}
			  alt="floor id icon"
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };
const Select1 = ({options,placeholder,onChange,value,defaultValue}) => {
	return (
	<>
    <Select 
    options={options}
    components={{ DropdownIndicator, ValueContainer,IndicatorSeparator:() => null }}
    styles={styles}
    placeholder={placeholder}
    onChange={(e)=> {onChange(e.value)}}
    value={value}
    defaultValue={defaultValue}
    />
  </>
	)
}

export default Select1;