

import React, { useState, useEffect } from "react";
import env from "../../env";
import "./login.css";
import AuthService from "../../Auth/auth";
import Health from "../Health/Health";
import spaceio from "../../services/spaceio-service"
import Loader from "../Loader"


function Login({ setroute }) {
	const [loading, setLoading] = useState(true)

const queryParams = new URLSearchParams(
    window.location.search
  );
  const [authCode] = useState(queryParams.get("xauthcode") || null);
  const [region] = useState(queryParams.get("region") || null);


  useEffect(() => {
    setLoading(true)
   async function fetchToken() {
      try {
        if (authCode) {
          const rep = await AuthService.getAuthToken(authCode,region);
          console.log(rep)
          if (rep) {
            const xAuthKey = rep.headers["x-auth-key"];
            window.localStorage.setItem("spaceio-key", xAuthKey);
            window.localStorage.setItem("spaceio-user", JSON.stringify(rep.data));
            spaceio.removeSensitiveParams();
            setroute("Health", null, null);
          }
        } 
        else{
          let spaceioKey  = window.localStorage.getItem("spaceio-key");
          // if(!spaceioKey){
          //   window.location.href = env.ADMINURL
          // }
          spaceio.removeSensitiveParams()
          setroute("Health",null,null)
        }


      } catch (e) {
        console.log("error", e);
      }
    }

    fetchToken();
  }, );


  

  return (<>
	 {loading && <Loader />}
  </>);
}

export default Login;
