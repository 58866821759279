import env, { mediaServerUrl } from "../env";
import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import _ from "lodash";
import Button1 from "./Button1";
import InfoBox from "./infoBox/InfoBox";
import alert from "../image/alert.svg";
import AuthService from "../Auth/auth";
let report;
let urlToHit;
let region = localStorage.getItem("region");

class mediacertificate extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
    this.state = {
      date: date,
      isVerified: "Not Calibarted",
      download: false,
      devicetable: true,
      floorplane: false,
      rowOfArray: [],
      error: null,
    };
  }

  componentDidMount = async () => {
    urlToHit = env.mediaServerCertificateUrl;
    report = this.props.report;
    let akey = await AuthService.fetchAuthKey();
    let data = await fetch(urlToHit, {
      method: "GET",
    //   headers: {'x-auth-key': `${akey}`, "Access-Control-Allow-Origin": "*" },
    });
    let databody = await data.json();
    // Assume data processing is done here and ready to download
    this.setState({ download: true, rowOfArray: databody });
  };

  // Function to convert JSON to CSV
  jsonToCsv = (data) => {
    const keyMapping = {
      accountId: "Account ID",
      id: "MS ID",
      created_at:"Certificate Created",
      location: "Location",
      accountName:"Account Name"
    };

    // Get the mapped keys for the header row
    const keys = Object.keys(data[0]);
    const mappedKeys = keys.map((key) => keyMapping[key] || key);
    const csvRows = [mappedKeys.join(",")];

    // Create the CSV rows
    for (const row of data) {
      const values = keys.map((key) => {
        const escapedValue = ("" + row[key]).replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  // Function to trigger download
  downloadCsv = (event) => {
    event.preventDefault();
    const date = new Date();
    // Extract year, month, and day from the date object
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero and get month in 2 digits
    const day = ("0" + date.getDate()).slice(-2); // Add leading zero and get day in 2 digits
    const hours = ("0" + date.getHours()).slice(-2); // Add leading zero and get hours in 2 digits
    const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero and get minutes in 2 digits
    const seconds = ("0" + date.getSeconds()).slice(-2); // Add leading zero and get seconds in 2 digits


    // Construct the filename in the desired format
    const filename = `media_server_certificate_data_${year}${month}${day}_${hours}${minutes}${seconds}.csv`;
    const { rowOfArray } = this.state;
    const csv = this.jsonToCsv(rowOfArray);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Reload the page
    window.location.href = window.location.href;
  };

  visitorreq;
  render() {
    return (
      <div>
        {!this.state.download ? (
          <InfoBox
            text={"Please Wait!  Preparing your report."}
            image={alert}
            background={"#ff5e5e1a"}
          />
        ) : (
          <InfoBox text={"Report is ready to be downloaded."} />
        )}

        <ButtonToolbar
          className="justify-content-center"
          style={{ columnGap: 30 }}
        >
          <Button1
            onClick={(e) => this.downloadCsv(e)}
            text={`Download ${region ? "UK" : region ? "US" : ""} Media Server Certificate Report`}
            borderRadius={"10px"}
            background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
            color={"#ffffff"}
            disabled={!this.state.download ? true : false}
            style={{
              cursor: !this.state.download ? "not-allowed" : "pointer",
              opacity: !this.state.download ? 0.1 : 1,
            }}
          />
        </ButtonToolbar>

        {this.state.error && (
          <div
            style={{
              textAlign: "center",
              paddingBottom: "15px",
              fontSize: "25px",
              color: "red",
            }}
          >
            <i>{this.state.error}</i>
          </div>
        )}
      </div>
    );
  }
}

export default mediacertificate;