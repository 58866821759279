import env from "../env";
import React from 'react';
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import _ from 'lodash';
import Button1 from './Button1';
import InfoBox from './infoBox/InfoBox'
import alert from '../image/alert.svg'
import DownloadService from "../services/download-service"
import AuthService from "../Auth/auth";
// import { Routes, Route, useNavigate } from 'react-router-dom';
const fs = require('fs');

let SiteName = [];
let location = [];
let outputHealth = [];
let AP_Name;
let type;
let report;
let urlToHit;
let region = localStorage.getItem('region');

const columnHeader = ["Account", "Location", "Total No Of Tim's", "Status", "Tim ID", "Last Connected", "Last Frame"];


class APtable extends React.Component {
	constructor(props) {
		super(props);
		var today = new Date(),
			date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
		this.state = {
			date: date,
			isVerified: 'Not Calibarted',
			download: false,
			devicetable: true,
			floorplane: false,
			rowOfArray: [],
			error: null

		};
		this.generateHeader = this.generateHeader.bind(this);
		this.generateTableData = this.generateTableData.bind(this);
		this.getSites = this.getSites.bind(this);
	}

	Backfrompg() {
		this.props.setroute(false);
	}

	excel(resp) {
		let csvContent;
		resp.forEach(function (rowArray) {
			let row = rowArray.join(",");
			csvContent += row + "\r\n";
		});
		fs.writeFile("Tim_health_report.csv", csvContent, function (err) {
			if (err) {
				return console.log(err);
			}
			console.log("The file was saved!");
		});
	}

	GenerateReport = () => {

	}

	generateHeader() {
		let res = [];
		for (var i = 0; i < columnHeader.length; i++) {
			res.push(
				<th key={columnHeader[i]}>{columnHeader[i]}</th>)
		}
		return res;
	}



	async getSites(us = false) {
		// console.log("getting sites")
		// console.log(this.props.type, "--------", this.props.report)
		type = this.props.type
		report = this.props.report

		// if (type == "TIM") {
		// 	console.log("there", type)
		// 	urlToHit = us ? env.tim : env.tim
		// }
		if (type == "TIM") {
			urlToHit = us ? env.timus : env.tim
		}
		else if (type == "TIM+") {
			urlToHit = us ? env.timpus : env.timp
		} else if (type == "FLO") {
			// console.log("here")
			urlToHit = us ? env.flous : env.flo
		}
		else if (type == "BAT-TIM") {
			urlToHit = us ? env.batTimus : env.batTim
		}
		else if (type == "PAM") {
			urlToHit = us ? env.pamcus : env.pamc
		}
		else if (type == "PAL") {
			urlToHit = us ? env.palus : env.pal
		}
		let akey = await AuthService.fetchAuthKey();
		
		let data = await fetch(urlToHit, {
			method: 'GET',
			headers: { 'x-auth-key': `${akey}`, "Access-Control-Allow-Origin": "*" }
		})
		let databody = await data.json();
		return databody;
	}
 

	downloadCsv = (e) => {
		// const navigate = useNavigate();
		e.preventDefault();

		window.location = window.location.href
		const rows = [];
		let csvContent = null;
		//Add title at the beginning
		// console.log("In downloadcsv");

		if (report == 'Consolidated') {
			if (type === "TIM") {
				rows.push('"Account","Location","Floor","Total No Of Tim","PIR Status","Tim ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
			else if (type === "TIM+") {
				rows.push('"Account","Location","Floor","Total No Of Tim+","PIR Status","Tim+ ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
			else if (type === "FLO") {
				rows.push('"Account","Location","Floor","Total No Of Flo","PIR Status","Flo ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
			else if (type === "BAT-TIM") {
				rows.push('"Account","Location","Floor","Total No Of BAT-TIM","PIR Status","BAT-TIM ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
			else if (type === "PAM") {
				rows.push('"Account","Location","Floor","Total No Of Pam","PIR Status","Pam ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
			else if (type === "PAL") {
				rows.push('"Account","Location","Floor","Total No Of PAL","PIR Status","PAL ID","Bless ID","Mac ID","Last Connected","Last Frame","Updated At","I2C Error","Restart Reason"')
			}
		}
		else if (report === "Detailed") {
			if (type === "TIM") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of Tim","Tim ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			else if (type === "TIM+") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of Tim+","Tim+ ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			else if (type === "FLO") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of Flo","Flo ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			else if (type === "BAT-TIM") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of BAT-TIM","BAT-TIM ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			else if (type === "PAL") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of PAL","PAL ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			else if (type === "PAM") {
				rows.push('"Account Id","Account Name","Location","Location Id","Floor","Total No Of Pam","Pam ID","Bless ID","Temperature","False On I2C","Free Memory on Device","Product Data","UTC Epoch","Device Type","EOM Time","SOM Time","STM","Heart Beat Frequency","I2C Heal Restart","Sensor on Board","True on I2C","Updated At","PIR Status","Mac ID","I2C Error","Wake up Reason","Restart Reason","Last Presence","Heart Beat Acknowledgement","Last Connected","Last Frame","Network Heal Restart","Model Name","Last Restart","Battery Voltage","Build Version","TZ Epoch","Processed At","Id"')
			}
			
		}
	
		_.each(this.state.rowOfArray, function (data) {
			rows.push(data.join(","));
		});
		csvContent = rows.join("\n");
		DownloadService.downloadCsv(csvContent, `${region ? 'UK' : (region ? 'US' : '')}_${this.props.type}_${this.props.report}_Report_${new Date().toDateString().replaceAll(' ', '_')}`);
		//console.log("----------------",this.props.akey)

		this.props.setroute("Health",null,null)

	};



	componentDidMount = async () => { // create the interval once component is mounted
		console.log("mounting component")
		let rowOfArray = [];
		let aoutput = [];
		let usoutput = [];
		//console.log("getting output", this.props.akey)
		if (region == 'UK') {
			aoutput = await this.getSites() || [];
		}
		if (region == 'US') {
			usoutput = await this.getSites(true) || []; // true for us 
		}
		let output = [];
		try {
			output.push(...aoutput)
		} catch (error) {
			console.error(error)
		}
		try {
			output.push(...usoutput)
		} catch (error) {
			console.error(error)
		}

		if (Array.isArray(output)) {
			for (let i = 0; i < output.length; i++) {
				let count = output[i].data.length; // Total number of tims in the account 
				for (let j = 0; j < count; j++) {
					let array = [];
					if (report == "Consolidated") {
						if (type === "TIM") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}

						}
						else if (type === "TIM+") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMpCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMpId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}

						}
						else if (type === "FLO") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].FLOCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].FLOId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}


						}
						else if (type === "BAT-TIM") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].batTimCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].batTimId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}


						}
						else if (type === "PAM") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PAMcCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PAMcId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}



						}
						else if (type === "PAL") {
							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PALCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PALId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push('Not supported')
								//array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError)
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart)
							} catch (error) {
								array.push('');
							}
						}
					}
					else if (report == "Detailed") {
						if (type === "TIM") {

							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.STM);// STM
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}

						}
						else if (type === "TIM+") {
							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMpCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].TIMpId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								if((output[i].data[j].data.STM).length != 0)
								{

									let convertedData = JSON.stringify(output[i].data[j].data.STM[0]).replace(/,/g, ' ');
									let trimmedString = convertedData.replace(/^\{|\}$/g, '');
									array.push(trimmedString)

								}else{
									array.push(output[i].data[j].data.STM);// STM
								}
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}

						}
						else if (type === "FLO") {
							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].FLOCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].FLOId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								if((output[i].data[j].data.STM).length != 0)
								{
									let convertedData = JSON.stringify(output[i].data[j].data.STM[0]).replace(/,/g, ' ');
									let trimmedString = convertedData.replace(/^\{|\}$/g, '');
									// console.log("convertedData",trimmedString)
									array.push(trimmedString)
								}
								else{
									array.push(output[i].data[j].data.STM);
								}
								
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}


						}
						else if (type === "BAT-TIM") {
							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].batTimCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].batTimId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.STM);// STM
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}


						}
						else if (type === "PAM") {
							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PAMcCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PAMcId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.STM);// STM
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}
						}
						else if (type === "PAL")
						{
							
							try {
								array.push(output[i].accountId);// Account Id 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].accountName.replace(/,/g, ' '));// Account Name 
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].location.replace(/,/g, ' ')); //Location
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].locationId); //Location Id
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].locationName.replace(/,/g, ' ')); //Floor
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PALCount);//Number of Tims 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].PALId);// TimId
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].blessId);// blessId
							} catch (error) {
								array.push('');
							}
							// try {
							// 	array.push(output[i].data[j].data.wifiPara);// WiFi Parameters
							// } catch (error) {
							// 	array.push('');
							// }

							try {
								array.push(output[i].data[j].data.temperature);// Temperature
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.falseOnI2C);// False On I2C
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.freeMemoryDevice);// Free Memory on Device
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.productData);// Product Data
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.utcEpoch);// UTC Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.deviceType);// Device Type
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.EOMtime);// EOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.SOMtime);// SOM Time
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.STM);// STM
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.heartbeatFreq);// Heart Beat Frequency
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.i2cHealRestart);// I2C Heal Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.sensorOnBoard);// Sensor on Board
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.trueOnI2C);// True on I2C
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.updatedAt * 1000).toLocaleDateString('en-GB'));//  Updated at 
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastState);// Status
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.macId);// MacID
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.i2cError) //I2C Error
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.wakeupReason);// "Wake up Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.errorRestart) //Restart Reason
							} catch (error) {
								array.push('');
							}
							try {
								array.push(output[i].data[j].data.lastPresence);// Last Presence
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.heartbeatAck);// Heart Beat Acknowledgement
							} catch (error) {
								array.push('');
							}
							try {
								array.push(new Date(output[i].data[j].data.lastConnect * 1000).toLocaleDateString('en-GB'));// Last Connect 
							} catch (error) {
								array.push('');
							}
							try {
								array.push('Not Supported')
								//array.push(new Date(output[i].data[j].data.lastFrame * 1000).toLocaleDateString('en-GB'));//  lastFrame
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.networkHealRestart);// MacID
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.modelName);// Model Name
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.lastRestart);// Last Restart
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.batteryVtg);// Battery Voltage
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.buildVersion);// Build Version
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.tzEpoch);// TZ Epoch
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.processedAt);// Processed At
							} catch (error) {
								array.push('');
							}

							try {
								array.push(output[i].data[j].data.id);// ID
							} catch (error) {
								array.push('');
							}


						}
					}
					rowOfArray.push(array);
				}
			}

			this.setState({
				download: true, rowOfArray
			})
		} else {
			this.setState({
				error: "There is an error in tim health api eg " + output.error
			})
		}
	}


	generateTableData() {
		let res = [];
		for (var i = 0; i < SiteName.length; i++) {
			for (var k = 0; k < AP_Name.length; k++) {
				res.push(
					<tr key={i + 0}>
						<td>{i + 1}</td>
						<td >{SiteName[i]}</td>
						<td>{location[i]}</td>
						<td>
							<td>{AP_Name[k]}</td>
						</td>
					</tr >
				)
			}
		}
		return res;
	}

	devicetablelist() {
		return (
			<div >

				<Table bordered hover style={{ marginTop: 10, marginBottom: 10 }}>
					<caption>List of devices</caption>
					<thead className="thead-dark">
						<tr >
							{this.generateHeader()}
						</tr>
					</thead>
					<tbody >
						{this.generateTableData()}
					</tbody>
				</Table>


			</div >
		)
	}



	render() {
		const { devicetable, floorplane } = this.state
		return (
			<div>

				{/* {!this.state.download ? <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "gray" }}><i>Processing Data...</i></div> : <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>Report is ready to be downloaded</i></div>} */}
				{!this.state.download ? <InfoBox text={"Please Wait!  Preparing your report."} image={alert} background={'#ff5e5e1a'} /> : <InfoBox text={"Report is ready to be downloaded."} />}

				<ButtonToolbar className="justify-content-center" style={{ columnGap: 30 }}>
					{/* <button type="button" className="btn genericButton  btn-lg btn-danger rounded" style={{borderRadius:"10px", background:"linear-gradient(90deg, #F7707D 0%, #F29173 100%)" ,color:"#ffffff",borderColor:"transparent"}} onClick={() => this.Backfrompg()} ><i className="fas fa-arrow-circle-left"></i> Sign Out</button> */}
					{/* <button type="button" className="btn genericButton  btn-lg btn-success rounded" disabled={this.state.download == false} onClick={() => this.downloadCsv()}>Download {this.props.type} Report <i className="fas fa-map-marked-alt"></i></button> */}

					<Button1 onClick={(e) => this.downloadCsv(e)}
						text={`Download ${region ? 'UK' : (region ? 'US' : '')} ${this.props.type} Report`}
						borderRadius={"10px"}
						background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
						color={"#ffffff"}
						disabled={!this.state.download ? true : false}
						style={{ cursor: !this.state.download ? "not-allowed" : "pointer", opacity: !this.state.download ? 0.1 : 1 }}
					/>

				</ButtonToolbar>


				{this.state.error && <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>{this.state.error}</i></div>}

			</div>

		)
	}
}

export default APtable;