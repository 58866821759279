// module.exports = {
// 	login: process.env.login || "https://accountws.afreespace.com/api/login",
// 	tim : process.env.tim ||'https://analytics.afreespace.com/apiv2/report/tim-health',
// }

module.exports = {

	loginURL: 'https://accountws.afreespace.com/api/login/code?requiredRefreshToken=true',
	loginURLUS: 'https://accountws.us.afreespace.com/api/login/code?requiredRefreshToken=true',

	refreshTokenURL: 'https://prodws.afreespace.com/api/refresh-token?requiredRefreshToken=true',
	refreshTokenURLUS: 'https://accountws.us.afreespace.com/api/refresh-token?requiredRefreshToken=true',

	ADMINURL: 'https://admin-temp.afreespace.com/',

	// login: process.env.login || "https://accountws.afreespace.com/api/login",
	// loginus: "https://accountws.us.afreespace.com/api/login",

	tim: process.env.tim || 'https://analytics.afreespace.com/apiv2/report/tim-health',
	timus: 'https://analytics.us.afreespace.com/apiv2/report/tim-health',

	timp: "https://analytics.afreespace.com/apiv2/report/tim_p-health",
	timpus: "https://analytics.us.afreespace.com/apiv2/report/tim_p-health",

	flo: "https://analytics.afreespace.com/apiv2/report/flo-health",
	flous: "https://analytics.us.afreespace.com/apiv2/report/flo-health",

	batTim: "https://analytics.afreespace.com/apiv2/report/bat_tim-health",
	batTimus: "",

	pamc: "https://analytics.afreespace.com/apiv2/report/pam_c-health",
	pamcus: "",

	pal: "https://analytics.afreespace.com/apiv2/report/pal-health",
	palus: "",
	
	base: "https://xrsvp8nwi5.execute-api.eu-west-1.amazonaws.com/prod",

	mediaServerUrl: "https://prodws.afreespace.com/api/admin/media-server",
    mediaServerCertificateUrl: "https://data.dev.afreespace.com/admin/new-certs-pending"
}

//before moving to prod, change all dev urls to prod
